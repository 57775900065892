"use client";
import { createContext, useContext, useEffect, useState } from "react";

interface ThemeContextI {
  theme: string;
  toggleTheme: () => void;
}

const themeContext = createContext<ThemeContextI>({
  theme: "dark",
  toggleTheme: () => {},
});

export function useThemeContext() {
  return useContext(themeContext);
}

const ThemeContextProvider = (props: any) => {
  const [theme, setTheme] = useState<string>("");

  const toggleTheme = () => {
    const body = document.querySelector("body");
    body?.classList.remove(theme);
    setTheme(theme === "dark" ? "light" : "dark");
  };

  useEffect(() => {
    const token = localStorage.getItem("theme");
    if (token) {
      setTheme(token);
    } else {
      setTheme("dark");
    }
  }, []);

  useEffect(() => {
    if (theme !== "") {
      const body = document.querySelector("body");
      body?.classList.add(theme);
      localStorage.setItem("theme", theme);
    }
  }, [theme]);

  const values: any = {
    theme,
    toggleTheme,
  };

  return (
    <themeContext.Provider value={values}>
      {props.children}
    </themeContext.Provider>
  );
};

export default ThemeContextProvider;
