import { PhotoI } from "@/interfaces/Galleries";

export const TransformToDays = (seconds: number) => {
  const s = seconds;
  const m = s / 60;
  const h = m / 60;
  const d = h / 24;
  return Math.floor(d);
};
export const TransformToHours = (seconds: number) => {
  const s = seconds;
  const m = s / 60;
  const h = m / 60;
  return Math.floor(h);
};

export const FormatStringDate = (date: string) => {
  const dateOfRegistration = new Date(date);
  return dateOfRegistration.toLocaleDateString();
};
export function formatDateTime(date: string): string {
  const dateObj = new Date(date);
  return dateObj.toLocaleString();
}
export function sortFromDateTaken(data: PhotoI[], order = "desc") {
  const sorted = data.sort((a, b) => {
    const { year: ay, day: ad, month: am } = a;
    const { year: by, day: bd, month: bm } = b;

    const stringA = `${am}/${ad}/${ay}`;
    const stringB = `${bm}/${bd}/${by}`;
    const dateA = new Date(stringA);
    const dateB = new Date(stringB);
    if (order === "asc") {
      return dateA.getTime() - dateB.getTime(); // Ascending order
    } else {
      return dateB.getTime() - dateA.getTime(); // Descending order
    }
  });
  return sorted;
}
