import axios from "axios";
import { celebApi } from "../api";

export const http_request = axios.create({
  baseURL: celebApi,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  // withCredentials: true, dejar comentando esto
});
