"use client";
import LoadingPage from "@/components/LoadingPage/LoadingPage";
import { LogI } from "@/interfaces/Logs";
import { CurrentUserI } from "@/interfaces/User";
import { getUser } from "@/services/AuthService";
import { createLog } from "@/services/LogService";
import { updateUserPoints } from "@/services/UserService";
import { TransformToDays } from "@/utils/Functions/Functionts";
import { notifyError } from "@/utils/NotificationFunctions";
import { createContext, useContext, useEffect, useState } from "react";

import ReactGA from "react-ga4";

ReactGA.initialize(`${process.env.REACT_APP_GA_MEASUREMENT_ID}`);

interface UserContextI {
  currentUser: CurrentUserI | null;
  userToken: string;
  handleLogin: (param: string) => void;
  handleLogout: () => void;
  fetchUserData: () => void;
  userLoader: boolean;
  ReactGA: any;
}

export const UserContext = createContext<UserContextI>({
  currentUser: null,
  userToken: "",
  handleLogin: () => {},
  handleLogout: () => {},
  fetchUserData: () => {},
  ReactGA: null,
  userLoader: false,
});

export function useUserContext() {
  return useContext(UserContext);
}

const UserContextProvider = (props: any) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [userToken, setUserToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [readOnly, setReadOnly] = useState<{ active: boolean; endDate: Date }>({
    active: false,
    endDate: new Date(),
  });
  const [startTime, setStartTime] = useState<number>(Date.now());
  const rejectReferral = async (user: any) => {
    const today = new Date();
    const todayInms = today.getTime();
    if (user?.pointHistory) {
      let ph = user?.pointHistory.filter(
        (p: any) =>
          p.reason &&
          p.status &&
          p.reason === "Referral" &&
          p.status === "not-granted" &&
          TransformToDays((todayInms - p.date) / 1000) >= 7
      );
      if (ph.length > 0) {
        try {
          await updateUserPoints(
            { userId: user._id, status: "removed" },
            userToken
          ).then(() => {
            notifyError("Bonus status: removed");
          });
        } catch (error) {}
        return "It worked";
      } else {
        return "Not possible";
      }
    }
  };
  const fetchUserData = async () => {
    try {
      await getUser(userToken).then((res) => {
        if (res && res.status !== "Internal Server Error") {
          const userData = {
            ...res.data,
            readOnly: false,
            readOnlyEndDate: new Date(),
            readOnlyReason: "",
            isSuspended: false,
            suspendedEndDate: new Date(),
            suspendedReason: "",
          };
          if (userData.status === "suspended") {
            const actualSuspended = userData.suspended[0];
            const today = new Date();
            const endDate = new Date(actualSuspended.endDate);
            if (endDate > today) {
              userData.suspendedEndDate = endDate;
              userData.isSuspended = true;
              userData.suspendedReason = actualSuspended.reason;
            }
          }
          if (userData.status === "ban") {
            const actualReadOnly = userData.read_only[0];
            const today = new Date();
            const endDate = new Date(actualReadOnly.endDate);
            if (endDate > today) {
              userData.readOnlyEndDate = endDate;
              userData.readOnly = true;
              userData.readOnlyReason = actualReadOnly.reason;
            }
          }
          rejectReferral(userData);
          setCurrentUser(userData);
          setLoading(false);
        } else {
          setUserToken("");
          setLoading(false);
        }
      });
    } catch (error) {
      console.error(error);
      localStorage.removeItem("user_token");
      setLoading(false);
    }
  };

  async function handleBeforeUnload() {
    if (startTime) {
      const endTime = Date.now();
      const timeSpent = endTime - startTime;
      const seconds = timeSpent / 1000;
      if (seconds > 1) {
        const log: LogI = {
          type: "Visit",
          typeNumber: 1,
          visitStart: startTime,
          visitEnd: endTime,
          visitTime: timeSpent,
        };
        await createLog(userToken, log);
      }
    }
  }
  useEffect(() => {
    const token = localStorage.getItem("user_token");
    const jwtCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("celeb-jwt="))
      ?.split("=")[1];
    if (token) {
      setUserToken(token);
    } else if (jwtCookie) {
      setUserToken(jwtCookie);
    } else {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    if (userToken) {
      fetchUserData();
      setStartTime(Date.now());
    }
  }, [userToken]);

  useEffect(() => {
    if (userToken) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () =>
        window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [userToken, startTime]);

  function handleLogin(token: string) {
    localStorage.setItem("user_token", token);
    setUserToken(token);
  }

  function handleLogout() {
    localStorage.removeItem("user_token");
    setUserToken("");
    setCurrentUser(null);
  }

  const values = {
    userToken,
    currentUser,
    fetchUserData,
    handleLogout,
    handleLogin,
    ReactGA,
    userLoader: loading,
  };
  return (
    <UserContext.Provider value={values}>
      {loading && <LoadingPage />}
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
