import { useThemeContext } from "@/context/ThemeContext";
import "./LoadingPage.scss";
const kalebLogo = "../../assets/images/LOGO.svg";

function LoadingPage() {
  const { theme } = useThemeContext();
  return (
    <div className={"loading-page-component-main-container " + theme}>
      <img src={kalebLogo} alt="celebstory" />
    </div>
  );
}

export default LoadingPage;
