import { LogI } from "@/interfaces/Logs";
import axios from "axios";
import { celebApi } from "./api";

export async function createLog(userToken: string, log: LogI) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .post(`${celebApi}/user/logActivity`, { log: log }, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}
